import React from 'react'
import './testimonials.css'
import AVT1 from '../../assets/tastimonials1.jpg'
import AVT2 from '../../assets/tastimonials2.jpg'
import AVT3 from '../../assets/tastimonials3.jpg'
import AVT4 from '../../assets/tastimonials4.jpg'

import { Pagination, } from 'swiper';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const data = [
    {
        avatar: AVT3,
        name: 'Jowita Wójcik',
        review: "Serdecznie polecam!  Poziom współpracy utrzymany na najwyższym standardzie. Wyróżnia się profesjonalizmem i punktualnością."
    },
    {
        avatar: AVT4,
        name: 'Artur Czerwiński',
        review: "Absolutny profesjonalizm, działanie z błyskawiczną szybkością, przy zachowaniu najwyższej jakości - bez dwóch zdań! Pełna satysfakcja z nawiązanej współpracy."
    },
    {
        avatar: AVT1,
        name: 'Aleksander Kowalczyk',
        review: "Znakomity profesjonalizm! Ogrom porad i wskazówek dotyczących rozwijania własnego e-sklepu. Indywidualne podejście do każdego klienta, zainteresowanie i gotowość do wsparcia, nawet po finalizacji projektu."
    },
    {
        avatar: AVT2,
        name: 'Michał Rutkowski',
        review: "Realizacja współpracy odbyła się płynnie, ekspresowo i zgodnie z ustalonymi terminami. Strona internetowa została stworzona z wielkim profesjonalizmem i spełniła wszystkie moje wymagania."
    },

]


const Testimonials_PL = () => {

    return (
        <section id='testimonials'>
            <h5 data-aos="fade-right">Sprawdź ostatnie</h5>
            <h3 data-aos="fade-left">Opinie klientów</h3>
            <Swiper className="container testimonials__container"
                modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                centeredSlides={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                loop={true}
                speed={2000}
                breakpoints={{
                    1024: {
                        slidesPerView: 2.5,
                    }
                }}
            >
                {
                    data.map(({ avatar, name, review }, index) => {
                        return (
                            <SwiperSlide className='testimonial' key={index}>
                                <div className="client__avatar">
                                    <img src={avatar} alt="Avatar one" />
                                </div>
                                <h5 className='client__name'>{name}</h5>
                                <small className='client__review'>{review}</small>

                            </SwiperSlide>
                        )
                    })
                }


            </Swiper>


        </section >
    )
}

export default Testimonials_PL