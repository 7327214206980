import React from 'react'
import HeaderPL from '../../header/Header_PL'
import Nav from '../../nav/Nav'
import AboutPL from '../../about/About_PL'
import PortfolioPL from '../../portfolio/Portfolio_PL'
import RealizationPL from '../../realizations/Realization_PL'
import TestimonialsPL from '../../testimonials/Testimonials_PL'
import ContactPL from '../../contact/Contact_PL'
import FooterPL from '../../footer/Footer_PL'

function Home() {
  return (

    <>
      <HeaderPL />
      <Nav />
      <AboutPL />
      <PortfolioPL />
      <RealizationPL />
      <TestimonialsPL />
      <ContactPL />
      <FooterPL />

    </>
  )
}

export default Home