import React, { useEffect } from 'react'
import './realization.css'
import IMG1 from '../../assets/jachty_laptop_fon.jpg'
import IMG2 from '../../assets/tkautomation screens_no_logo.jpg'
import IMG3 from '../../assets/magnolialwiaciarnia_laptop_fon.jpg'
import IMG4 from '../../assets/dancar.jpg'
import IMG5 from '../../assets/pokonajlabirynt.png'
import IMG6 from '../../assets/zafakturuj.png'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'jachty-autopol.pl',
    desc: 'Jachty-autopol.pl to nowoczesny sklep internetowy dla miłośników żeglarstwa. Oferujemy czartery jachtów, szkolenia żeglarskie i rejsy morskie na ciepłych wodach. Platforma łączy funkcjonalność i estetykę, tworząc idealne miejsce dla pasjonatów morskich przygód.',
    demo: 'https://jachty-autopol.pl',
  },
  {
    id: 2,
    image: IMG2,
    title: 'tkautomation.pl',
    desc: 'Tkautomation.pl to profesjonalna strona firmy zajmującej się automatyzacją przemysłową. Oferujemy kompleksowe rozwiązania oparte na nowoczesnych technologiach, które usprawniają procesy produkcyjne. Intuicyjna nawigacja i opinie klientów potwierdzają naszą niezawodność.',
    demo: 'http://tkautomation.pl',
  },
  {
    id: 3,
    image: IMG3,
    title: 'kwiaciarniamagnolia.com.pl',
    desc: 'Magnoliakwiaciarnia.pl to elegancka strona Kwiaciarni Magnolia z Włocławka. Od ponad 20 lat dostarczamy wyjątkowe kompozycje kwiatowe na śluby, przyjęcia i inne okazje. Intuicyjna platforma z możliwością dostawy zapewnia wygodę i profesjonalizm.',
    demo: 'http://kwiaciarniamagnolia.com.pl',
  },
  {
    id: 4,
    image: IMG6,
    title: 'zafakturuj.pl',
    desc: 'Zafakturuj.pl to intuicyjna platforma do szybkiego wystawiania faktur online. Idealna dla małych firm i freelancerów, oferuje zarządzanie fakturami, monitoring płatności oraz personalizację dokumentów – wszystko w jednym miejscu.',
    demo: 'https://zafakturuj.pl',
  },
  {
    id: 5,
    image: IMG5,
    title: 'pokonajlabirynt',
    desc: 'Pokonajlabirynt.com.pl to interaktywna platforma gier logicznych z różnorodnymi poziomami trudności i atrakcyjną grafiką. Dostępna na przeglądarki i Androida, oferuje tablice wyników, porady oraz emocjonującą zabawę dla graczy w każdym wieku.',
    demo: 'https://pokonajlabirynt.com.pl',
  },
];



const Portfolio_PL = () => {
  useEffect(() => {

    gsap.to('.realizations__bg__square--right', {
      scrollTrigger: {
        trigger: '.realizations__bg__square--right',
        scrub: true,
        start: 'top bottom', // Animation start point
        end: 'bottom top', // Animation end point
      },
      y: 200, // Move up and down by 100px
      rotation: 90, // Rotate 360 degrees
      ease: 'none'
    });

    gsap.to('.realizations__bg__square--left', {
      scrollTrigger: {
        trigger: '.realizations__bg__square--left',
        scrub: true,
        start: 'top bottom',
        end: 'bottom top',
      },
      y: -200, // Move up and down by -100px (opposite direction)
      rotation: -50, // Rotate -360 degrees
      ease: 'none'
    });

  }, []);


  return (
    <section id='realizations'>
      <div className="realizations__wrapper">

        <h2>Nasze realizacje</h2>
        <p>
          Z dumą prezentujemy projekty, które pomagają naszym klientom osiągać ich cele biznesowe.
          Każda realizacja to przykład naszych umiejętności w tworzeniu skutecznych i nowoczesnych stron internetowych, sklepów online oraz aplikacji.
          Zobacz, jak zmieniamy wizje w sukcesy!
        </p>
        <a href="https://jachty-autopol.pl/">
          <div className="realizations__image">
            {/* <img src={MONITOR} alt="" /> */}
          </div>
        </a>

      </div>

      <div className="realizations__items">

        <Swiper className="container realizations__container"
          spaceBetween={50}
          slidesPerView={1.3}
          // scrollbar={{ draggable: true }}
          loop={true}
          speed={2000}
          centeredSlides={false}
          breakpoints={{
            1024: {
              slidesPerView: 1.5,
              centeredSlides: true,
            }
          }}

        >
          {
            data.map(({ image, title, desc, demo }, index) => {
              return (
                <SwiperSlide className='realization' key={index}>
                  <a href={demo}>
                    <div className="realization__image">
                      <img src={image} alt="Avatar one" />
                    </div>
                    <h3 className='realization__title'>{title}</h3>
                    <p className='realization__desc'>{desc}</p>
                  </a>

                </SwiperSlide>
              )
            })
          }


        </Swiper>
      </div>

      <div className="realizations__bg__square--right"></div>
      <div className="realizations__bg__square--left"></div>
    </section >
  )
}

export default Portfolio_PL