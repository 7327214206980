
document.addEventListener('DOMContentLoaded', () => {
    const images = document.querySelectorAll('img');
    const paragraphs = document.querySelectorAll('p');
    const headersh2 = document.querySelectorAll('h2');
    const headersh1 = document.querySelectorAll('h1');
    const headersh3 = document.querySelectorAll('h3');
    const headersh4 = document.querySelectorAll('h4');
    const headersh5 = document.querySelectorAll('h5');

    images.forEach((e) => {
        if (!e.classList.contains('animation-off')) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const intersecting = entry.isIntersecting;
                    if (intersecting) { addClass(e); }

                });
            });
            observer.observe(e);
        }
    });

    headersh1.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }

            });
        });
        observer.observe(e);
    });

    headersh2.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }
            });
        });
        observer.observe(e);
    });

    headersh3.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }
            });
        });
        observer.observe(e);
    });

    headersh4.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }

            });
        });
        observer.observe(e);
    });

    headersh5.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }

            });
        });
        observer.observe(e);
    });

    paragraphs.forEach((e) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const intersecting = entry.isIntersecting;
                if (intersecting) { addClass(e); }

            });
        });
        observer.observe(e);
    });

    function addClass(e) {
        setTimeout(() => {
            e.classList.add('animate');
        }, 200);
    }
})
