import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './blog.css';
import img1 from '../../assets/blog/blog (1).webp';
import img2 from '../../assets/blog/blog (2).webp';
import img3 from '../../assets/blog/blog (3).webp';
import img4 from '../../assets/blog/blog (4).webp';
import img5 from '../../assets/blog/blog (5).webp';
import logo from '../../assets/logo2.png';
import generateSlug from '../utils/GenerateSlug';


const posts = [
    { id: 1, title: 'Jak nowoczesna strona internetowa może przyciągnąć więcej klientów', content: 'Odkryj, jak profesjonalnie zaprojektowana strona internetowa, wykorzystująca HTML, CSS i JavaScript, może poprawić wizerunek Twojej firmy i przyciągnąć więcej klientów. Dowiedz się, jak atrakcyjny design i intuicyjna nawigacja wpływają na decyzje zakupowe.', img: img1, category: 'Rozwój biznesu', slug: 'jak-nowoczesna-strona-internetowa-moze-przyciagnac-wiecej-klientow.html' },
    { id: 2, title: 'Dlaczego wybór odpowiedniego frameworka front-endowego jest kluczowy dla Twojego biznesu', content: 'Poznaj, jak wybór między Angular, React, a Vue może wpłynąć na szybkość, wydajność i skalowalność Twojej aplikacji internetowej, co bezpośrednio przekłada się na zadowolenie użytkowników i potencjalny wzrost przychodów.', img: img2, category: 'Technologia', slug: 'dlaczego-wybor-odpowiedniego-frameworka-front-endowego-jest-kluczowy-dla-twojego-biznesu.html' },
    { id: 3, title: 'Responsywne strony internetowe: Dlaczego są one ważne dla Twojego biznesu', content: 'Zrozum, jak technologie Flexbox i Grid CSS umożliwiają tworzenie responsywnych stron, które doskonale wyglądają na każdym urządzeniu. Dowiedz się, jak poprawa dostępności strony może zwiększyć Twoje zasięgi i sprzedaż.', img: img3, category: 'Marketing', slug: "responsywne-strony-internetowe.html" },
    { id: 4, title: 'SEO: Twoja strona internetowa na szczycie wyników wyszukiwania', content: 'Poznaj podstawowe techniki SEO, które pomogą Twojej stronie internetowej osiągnąć wyższą pozycję w wynikach wyszukiwania, zwiększając jej widoczność dla potencjalnych klientów.', img: img4, category: 'SEO i Marketing', slug: 'seo-twoja-strona-internetowa-na-szczycie-wynikow-wyszukiwania.html' },
    { id: 5, title: 'Progressive Web Apps: Przyszłość mobilnego internetu dla biznesu', content: 'Dowiedz się, jak Progressive Web Apps (PWA) łączą najlepsze cechy stron internetowych i aplikacji mobilnych, oferując użytkownikom szybkie i atrakcyjne doświadczenia, które mogą zwiększyć zaangażowanie i konwersję.', slug: 'progressive-web-apps-przyszlosc-mobilnego-internetu-dla-biznesu.html', img: img5, category: 'Innowacje', },
    { id: 6, title: 'Optymalizacja wydajności strony: Jak szybkość ładowania wpływa na Twój biznes', content: 'Odkryj, jak poprawa szybkości ładowania strony może znacząco wpłynąć na zadowolenie klientów i pozycję w wynikach wyszukiwania, co przekłada się na lepsze wskaźniki konwersji i zwiększone przychody.', slug: 'optymalizacja-wydajnosci-strony-jak-szybkosc-ladowania-wplywa-na-twoj-biznes.html', img: img1, category: 'Optymalizacja', },
    { id: 7, title: 'E-commerce: Jak zbudować skuteczny i przynoszący zyski sklep internetowy', content: 'Zrozum, jakie elementy składają się na sukces sklepu internetowego, od wyboru platformy po strategie marketingowe, które pomogą Ci przyciągnąć i zatrzymać klientów.', slug: 'e-commerce-jak-zbudowac-skuteczny-i-przynoszacy-zyski-sklep-internetowy.html', img: img2, category: 'E-commerce', },
    { id: 8, title: 'Integracja systemów płatności: Klucz do łatwych i bezpiecznych transakcji online', content: 'Dowiedz się, jak integracja niezawodnych systemów płatności może usprawnić proces zakupu w Twojej aplikacji webowej, zwiększając zaufanie i satysfakcję klientów.', slug: 'integracja-systemow-platnosci-klucz-do-latwych-i-bezpiecznych-transakcji-online.html', img: img3, category: 'Finanse', },
    { id: 9, title: 'Zabezpieczenia stron internetowych: Jak chronić dane swojej firmy i klientów', content: 'Poznaj najlepsze praktyki bezpieczeństwa w sieci, które pomogą Ci ochronić swoją stronę internetową przed atakami hakerskimi i wyciekiem danych, budując zaufanie klientów i zapewniając bezpieczeństwo transakcji online.', slug: 'zabezpieczenia-stron-internetowych-jak-chronic-dane-swojej-firmy-i-klientow.html', img: img4, category: 'Bezpieczeństwo', },
    { id: 10, title: 'Wykorzystanie API do rozwijania funkcjonalności biznesowych', content: 'Odkryj, jak integracja zewnętrznych API może wzbogacić Twoją aplikację webową o nowe funkcje, takie jak płatności, mapy czy integracje społecznościowe, zwiększając jej wartość i użyteczność dla użytkowników.', slug: 'wykorzystanie-api-do-rozwijania-funkcjonalnosci-biznesowych.html', img: img5, category: 'Rozwój technologiczny', }
];


const BlogPosts = () => {
    const [selectedCategory, setSelectedCategory] = useState('Wszystkie');
    const [searchQuery, setSearchQuery] = useState('');

    const categories = useMemo(() => {
        const allCategories = posts.map(post => post.category);
        return ['Wszystkie', ...new Set(allCategories)];
    }, []);

    const filteredPosts = posts.filter(post => {
        return (selectedCategory === 'Wszystkie' || post.category === selectedCategory) && post.title.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (

        <div className='blog'>
            <div class="header header-blog">
                <div class="container">
                    <div class="header__wrapper--blog">
                        <div class="header__logo">
                            <a href="/">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div class="header__nav">
                            <ul>
                                <li>
                                    <a href="/">Strona Główna</a>
                                    <a href="/blog">Blog</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="blog__inner">
                    <div className="blog__header">
                        <h2>Blog</h2>
                    </div>
                    <div className="blog__filters">

                        <div className='blog__categories'>
                            {categories.map(category => (
                                <button
                                    key={category}
                                    onClick={() => setSelectedCategory(category)}
                                    className={selectedCategory === category ? 'active' : ''}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        <div className="blog__search">
                            <input
                                type="text"
                                placeholder="Szukaj..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="blog__wrapper">
                        {filteredPosts.length > 0 ? (
                            filteredPosts.map(post => (
                                <a key={post.id} href={`/blog/${post.slug}`} className='blog__post'>
                                    <img src={post.img} alt={post.title} />
                                    <div className="blog__post__content">
                                        <h5>{post.title}</h5>
                                        <p>{post.content}</p>
                                    </div>
                                    <p className='blog__post__category'>{post.category}</p>
                                </a>
                            ))
                        ) : (
                            <div className="no-results">Brak wyników.</div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BlogPosts;

