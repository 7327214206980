import React, { useState } from 'react';
import './portfolio.css';

import MONITOR from '../../assets/monitor.png';
import IMG1 from '../../assets/magnolialwiaciarnia_laptop_fon.jpg';
import IMG2 from '../../assets/jachty_laptop_fon.jpg';
import IMG4 from '../../assets/tkautomation screens_no_logo.jpg';

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'STRONY FIRMOWE',
    desc: 'Tworzymy strony firmowe, które skutecznie prezentują Twoje produkty i usługi. Każdy projekt dostosowujemy do indywidualnych potrzeb Twojej firmy, dbając o przejrzystość, łatwą nawigację i profesjonalny wizerunek. Dzięki naszym stronom przyciągniesz więcej klientów i zbudujesz zaufanie do swojej marki.',
    demo: 'https://magnoliakwiaciarnia.pl',
  },
  {
    id: 2,
    image: IMG2,
    title: 'SKLEPY INTERNETOWE',
    desc: 'Zaprojektujemy sklep internetowy, który wyróżni Twoją markę i zwiększy sprzedaż. Nasze sklepy są intuicyjne, szybkie i zoptymalizowane pod kątem SEO, co pomaga przyciągnąć nowych klientów i poprawić widoczność w internecie. Dzięki naszym rozwiązaniom Twój e-commerce będzie działał efektywnie i przynosił zyski.',
    demo: 'https://jachty-autopol.pl/',
  },
  {
    id: 3,
    image: IMG1,
    title: 'STRONY WIZERUNKOWE',
    desc: 'Budujemy nowoczesne strony wizerunkowe, które pomagają wyróżnić Twoją markę na tle konkurencji. Nasze wizytówki łączą estetykę z funkcjonalnością, co pozwala szybko budować zaufanie wśród klientów. Oferujemy również pełne wsparcie techniczne, dzięki czemu masz pewność, że Twoja strona będzie działać bez zarzutu.',
    demo: 'http://dancarkontenery.pl/',
  },
  {
    id: 4,
    image: IMG4,
    title: 'LANDING PAGES',
    desc: 'Tworzymy skuteczne landing pages, które koncentrują się na jednym celu – maksymalizacji konwersji. Dzięki naszym stronom docelowym możesz generować więcej leadów, zwiększać sprzedaż lub efektywnie promować swoje produkty. Zadbamy o unikalny design, szybkie ładowanie oraz zoptymalizowaną strukturę, która zachęca do działania.',
    demo: 'http://tkautomation.pl/',
  },
];

const Portfolio_PL = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section id='portfolio' data-aos="zoom-out-up">
      <h2>Oferta</h2>
      <h3>Witamy w sercu tego, co tworzymy!</h3>
      <p>
        Łączymy kreatywność, doświadczenie i zaangażowanie,
        aby oferować szeroką gamę usług dopasowanych do Twoich potrzeb.
        Niezależnie od tego, czy jesteś startupem, ugruntowaną firmą, czy klientem indywidualnym,
        tworzymy rozwiązania, które wyróżniają Twoją markę i zamieniają wizję w rzeczywistość.
      </p>

      <a href="https://jachty-autopol.pl/">
        <div className="portfolio__image">
          <img src={MONITOR} alt="" />
        </div>
      </a>

      <div className="portfolio__items">
        {data.map(({ id, image, title, desc }) => (
          <div key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
              <h3>{title}</h3>
              <ul>
                <li>{desc}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Wakacyjna Promocja!</h2>
            <p>Skorzystaj z naszej specjalnej oferty na strony internetowe z rabatem -30%! Aby skorzystać z promocji, napisz do nas maila z hasłem <strong>Sierpień30</strong> <br></br> <h4>email: kontakt@woosu.pl</h4>.</p>

            <div className="popup__buttons">
              <a class="btn btn--primary" href="#contact" onClick={handleClosePopup}>Napisz do nas!</a>
              <button className="popup-close" onClick={handleClosePopup}>Zamknij</button>
            </div>
          </div>
        </div>
      )
      }
    </section >
  );
};

export default Portfolio_PL;
