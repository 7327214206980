import React from 'react'
import Nav from '../../nav/Nav'
import BlogPosts from '../../blog/BlogPosts'
import Footer from '../../footer/Footer_PL'
function Blog() {
    return (

        <>
            <Nav />
            <BlogPosts />
            <Footer />
        </>
    )
}

export default Blog