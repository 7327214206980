import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './components/pages/home/Home';
import Blog from './components/pages/blog/Blog';


const App = () => {
  return (

    <div className="app app-hidden">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
    </div>
  )
}


export default App