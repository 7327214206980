import ReactDom from 'react-dom';
import App from './App';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import './animation';


ReactDom.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector('#root'))

