import React from 'react'
import './footer.css'
import logo from '../../assets/logo2.png'
const Footer_PL = () => {
  return (
    <section id='footer'>
      <footer>
        <a href="#" className='footer__logo'><img src={logo} alt="" /></a>
        <div className="footer__copyright">
          Copyright © 2024  WooSu
        </div>
      </footer>
    </section>

  )
}

export default Footer_PL