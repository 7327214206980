import React, { useRef, useEffect, useState } from 'react';
import './header.css';
import bgMovie from '../../assets/bg-movie.mp4';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Header_PL = () => {
  const [offset, setOffset] = useState(0);
  const maxScrollWidth = useRef(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const headerRef = useRef(null);
  const textRef = useRef(null);
  const textRefSecond = useRef(null);
  const textRefThird = useRef(null);
  const body = document.querySelector('body');

  useEffect(() => {
    maxScrollWidth.current = window.innerWidth;

    ScrollTrigger.create({
      trigger: headerRef.current,
      start: "top top",
      pin: true,
      pinSpacing: false,
      end: `+=${maxScrollWidth.current}`,
      scrub: true,
      onLeave: () => { body.style.overflow = 'visible'; },
      onUpdate: (self) => {
        const progress = self.progress * maxScrollWidth.current;
        setOffset(progress);
      }
    });

    return () => {
      ScrollTrigger.kill();
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Ustaw początkową szerokość
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.style.transform = `translateX(${offset}px)`;
    }

    if (textRefSecond.current) {
      textRefSecond.current.style.transform = `translateX(-${offset}px)`;
    }

    if (textRefThird.current) {
      textRefThird.current.style.transform = `translateX(${offset}px)`;
    }
  }, [offset]);

  return (
    <header id="header" ref={headerRef}>
      <div className="header__nav nav--home">
        <ul>
          <li>
            <a href="/">Strona Główna</a>
            <a href="/blog">Blog</a>
          </li>
        </ul>
      </div>
      <div className="header__bg">
        <video muted autoPlay loop className="video-bg">
          <source src={bgMovie} type="video/mp4" />
        </video>
      </div>

      <div className="container header__container">
        <div className="header__wrapper">
          <h1 className="header__first" ref={textRef}>Tworzenie</h1>
          <h1 className='header__second' ref={textRefSecond}>Przyszłości</h1>
          <h1 className='header__third' ref={textRefThird}>Cyfrowej</h1>

          <h3 className='text-light'>Twój Partner w Rozwoju Stron Internetowych, Sklepów i Aplikacji</h3>

        </div>
      </div>
    </header>


  )
}

export default Header_PL
